import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommonDataService } from "../../../common/services/commonData.service";
import { CommonService } from "src/app/common/services/common.service";

@Injectable({
    providedIn: "root"
})
export class ClarificationService {
    constructor(
        private _commonDataService: CommonDataService,
        private _commonService: CommonService
    ) { }
    getHrConfirmTypes<T>(): Observable<T[]> {
        return this._commonDataService
            .getDataAsync("api/Profile/GetHrConfirmTypes");
    }

    getHrConfirmSubTypes<T>(): Observable<T[]> {
        return this._commonDataService
            .getDataAsync("api/Profile/GetHrConfirmSubTypes");
    }

    getHRConfirmReqList<T>(data: any): Observable<T> {
        return this._commonDataService
            .postDataAsync("api/HrConformation/List", data)
           ;
    }
    getHRConfirmReqListExport(data: any) {
        this._commonDataService
          .postDataGetBlobAsync("api/HrConformation/ListExport", data).subscribe((res: any) => {
            this._commonService.downLoadExcel(res, 'HRConfirmList.xlsx');
          });
      }

    getHrConfirmReqLogList<T>(id: number): Observable<T> {
        return this._commonDataService
            .getDataAsync("api/HrConformation/GetHrConfirmReqLogList?hrConformationRequestId=" + id)
           ;
    }
    getHrConformationFiles<T>(id: number): Observable<T> {
        return this._commonDataService
            .getDataAsync("api/HrConformation/GetHrConformationFiles?hrConformationRequestId=" + id)
           ;
    }

    deleteHrConformationFileById<T>(id: number): Observable<T> {
        return this._commonDataService
            .getDataAsync("api/HrConformation/DeleteHrConformationFileById?hrConfReqFileId=" + id)
           ;
    }

    changeStatus<T>(request: any): Observable<T> {
        return this._commonDataService
            .postDataAsync("api/HrConformation/ChangeStatus", request)
           ;
    }

    getInsuffFiles<T>(id: any): Observable<T> {
        return this._commonDataService
            .getDataAsync("api/HrConformation/GetInsuffFiles?id=" + id)
           ;
    }
    getICACounts<T>(): Observable<T> {
        return this._commonDataService
            .getDataAsync("api/HrConformation/GetICACounts")
           ;
    }

    getProfileCount<T>(request: any): Observable<T> {
        return this._commonDataService
            .postDataAsync<T>("api/HrConformation/GetProfileCount", request)
           ;
    }
    
    getMailsCount<T>(request: any): Observable<T> {
        return this._commonDataService
            .postDataAsync("api/HrConformation/GetMailsCount",request)
           ;
    }

    getReportTypesCount<T>(request: any): Observable<T> {
        return this._commonDataService
            .postDataAsync("api/HrConformation/GetReportTypesCount",request)
           ;
    }
}
